import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Container, Row } from "react-bootstrap"
import Overview from "../../../assets/images/campaign-survey/overview.png"

const Hero = () => {
  const intl = useIntl()

  return (
    <section className="pt-6 pt-md-8">
      <Container>
        <Row className="align-items-center justify-content-center">
          <div className="col-10 col-sm-8 col-md-4 order-md-2">
            <img className="img-fluid" src={Overview} alt="..." />
          </div>
          <div className="col-12 col-md-8 col-lg-5" data-aos="fade-right">
            {/* Heading */}
            <h1
              className="font-weight-bold"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  defaultMessage:
                    'The fastest and easiest way to gather immediate actionable <span class="text-whatsapp">feedback</span> from <span class="text-whatsapp">your event</span>.',
                }),
              }}
            />

            {/* Text */}
            <p className="font-size-lg text-muted mb-6">
              {intl.formatMessage({
                defaultMessage: "All automated. No technical knowledge required.",
              })}
            </p>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default Hero

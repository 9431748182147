import { useIntl } from "gatsby-plugin-intl"
import React from "react"
import { Badge, Col, Container, Row } from "react-bootstrap"
import FourthPicture from "../../../assets/images/campaign-survey/undraw_Artificial_intelligence_oyxx.svg"
import FirstPicture from "../../../assets/images/campaign-survey/undraw_collecting_fjjl.svg"
import SecondPicture from "../../../assets/images/campaign-survey/undraw_customer_survey_f9ur.svg"
import ThirdPicture from "../../../assets/images/campaign-survey/undraw_data_trends_b0wg.svg"
import "./scss/index.scss"

const Steps = () => {
  const intl = useIntl()

  return (
    <section className="py-8 steps bg-light">
      <Container>
        <Row className="mb-5 pb-5">
          <Col className="text-center">
            <h2>{intl.formatMessage({ defaultMessage: "How does it work?" })}</h2>
            <span className="lead">
              {intl.formatMessage({
                defaultMessage:
                  "Your guests receive an automated invitation with best-practice questions to give feedback about your event in their own words. Actionable feedback appears instantly in your dashboard.",
              })}
            </span>
          </Col>
        </Row>

        <Row className="bg-white">
          <Col xs={12} className="mt-n5 text-center">
            <Badge className="my-3 whatsapp-bg-color">
              <p className="lead mb-0">
                {intl.formatMessage({ defaultMessage: "A step-by-step guide" })}
              </p>
            </Badge>
          </Col>

          {/* Text left */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 2 }} className="text-center py-3">
              <img src={FirstPicture} alt="..." className="steps__step-svg" data-aos="fade-left" />
            </Col>
            <Col xs={{ span: 8, order: 1 }} data-aos="fade-right">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 1" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "With each sold ticket, we help you to collect the email address with GDPR conform consent from your attendees.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage: "We help you to collect up to 5x more emails.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text right */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 1 }} className="text-center py-3">
              <img
                src={SecondPicture}
                alt="..."
                className="steps__step-svg"
                data-aos="fade-right"
              />
            </Col>
            <Col xs={{ span: 8, order: 2 }} data-aos="fade-left">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 2" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "We generate an industry best-practice survey and automatically distribute them to your guests.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "This step is completely automated. It will cost you no time. Each of your guest receives a survey with simple, but insightful questions to share their feedback.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text left */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 2 }} className="text-center py-3">
              <img src={ThirdPicture} alt="..." className="steps__step-svg" data-aos="fade-left" />
            </Col>
            <Col xs={{ span: 8, order: 1 }} data-aos="fade-right">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 3" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "Feedback arrives for you in real-time to explore and act instantly.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({
                  defaultMessage:
                    "As soon as your guests share their feedback, it is available for exploration. Additionally, we provide you with guided reports, to help you and your team to prioritise what matters.",
                })}
              </p>
            </Col>
          </Row>

          {/* Text right */}
          <Row className="py-5 mx-5 align-items-center">
            <Col xs={{ span: 4, order: 1 }} className="text-center py-3">
              <img
                src={FourthPicture}
                alt="..."
                className="steps__step-svg"
                data-aos="fade-right"
              />
            </Col>
            <Col xs={{ span: 8, order: 2 }} data-aos="fade-left">
              <Badge className="my-3 whatsapp-bg-color">
                <p className="lead mb-0">{intl.formatMessage({ defaultMessage: "Step 4" })}</p>
              </Badge>
              <h2 className="font-weight-bold">
                {intl.formatMessage({
                  defaultMessage:
                    "State-of-the-art machine learning will analyse all answers and extract the most important topics for you.",
                })}
              </h2>

              <p className="font-size-lg text-muted mb-0">
                {intl.formatMessage({ defaultMessage: "Understand what matters within seconds." })}
              </p>
            </Col>
          </Row>
        </Row>
      </Container>
    </section>
  )
}

export default Steps
